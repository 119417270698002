<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">企业内训</a>
          <i>></i>
          <a href="javascript:;" class="cur-a" @click="$router.back()">学习数据</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">查看详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl" style="border-bottom: none;">
            <div class="searchbox" style="margin-bottom: 5px">
                <div title="学员姓名" class="searchboxItem ci-full">
                    <span class="itemLabel">学员姓名:</span>
                    <el-input
                        v-model="ruleForm.userName"
                        type="text"
                        size="small"
                        placeholder="请输入学员姓名"
                        clearable
                    />
                </div>
                <div title="手机号" class="searchboxItem ci-full">
                    <span class="itemLabel">手机号:</span>
                    <el-input
                        v-model="ruleForm.mobile"
                        type="text"
                        size="small"
                        placeholder="请输入手机号"
                        clearable
                    />
                </div>
                <div title="学习状态" class="searchboxItem ci-full">
                    <span class="itemLabel">学习状态:</span>
                    <el-select
                        clearable
                        size="small"
                        v-model="ruleForm.completeState"
                        placeholder="请选择"
                    >
                        <el-option
                        v-for="item in studyStatusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div class="btnBox" style="margin-left: 20px">
                    <el-button
                    style="margin-left: 20px"
                    class="bgc-bv"
                    round
                    @click="getData()"
                    >查询</el-button
                    >
                    <el-button class="bgc-bv" round @click="exportData"
                    >导出</el-button
                    >
                </div>
            </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed="left"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="学员姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                fixed="left"
                min-width="100"
              />
              <el-table-column
                label="手机号码"
                align="left"
                prop="mobile"
                show-overflow-tooltip
                fixed="left"
                min-width="150"
              />
              <el-table-column
                label="学习状态"
                align="left"
                prop="studyState"
                show-overflow-tooltip
                min-width="120"
              />
              <el-table-column
                label="已学时长"
                align="left"
                prop="totalNum"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="开始学习时间"
                align="left"
                prop="startTime"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="完成学习时间"
                align="left"
                prop="endTime"
                show-overflow-tooltip
                min-width="200"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "platformOfquestionBankList",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  doNotInit:true,
  data() {
    return {
      ruleForm: {
        userName:'',//学员姓名
        mobile:'',//学员手机
        completeState:'',//学员状态
      },
      studyStatusList:[
        {
          label: "未学习",
          value: "10"
        },
        {
          label: "学习中",
          value: "20"
        },
        {
          label: "已完成",
          value: "30"
        },
        {
          label: "未完成",
          value: "40"
        }
      ],//学习状态list
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getTableHeight();
    // this.getstydyStatusList()
    if(this.$route.query.projectId){
        this.getData()
    }
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId:this.$route.query.projectId
      };
      if (this.ruleForm.userName) {
        params.userName = this.ruleForm.userName;
      }
      if (this.ruleForm.mobile) {
        params.mobile = this.ruleForm.mobile;
      }
      if (this.ruleForm.completeState) {
        params.completeState = this.ruleForm.completeState;
      }
      this.doFetch({
        url: "/biz/learning/details",
        params,
        pageNum,
      });
    },
    // 学习状态list
    getstydyStatusList() {
      const studyStatusList = this.$setDictionary("STUDY_STATUS", "list") || [];
      let list = [];
      for (const key in studyStatusList) {
        list.push({
          value: key,
          label: studyStatusList[key],
        });
      }
      this.studyStatusList = list;
    },
    // 导出
    exportData() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
            const params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                projectId:this.$route.query.projectId
            };
            if (this.ruleForm.userName) {
                params.userName = this.ruleForm.userName;
            }
            if (this.ruleForm.mobile) {
                params.mobile = this.ruleForm.mobile;
            }
            if (this.ruleForm.completeState) {
                params.completeState = this.ruleForm.completeState;
            }
            this.$post("/biz/learning/details/export", params).then((res) => {
                if (res.status == "0") {
                let list = res.data;
                for (let item of list) {
                    console.log(item);
                    if (!this.downloadItems.includes(item.taskId)) {
                    this.$store.dispatch("pushDownloadItems", item.taskId);
                    } else {
                    this.$message.warning(
                        "[" + item.fileName + "]已经申请下载,请耐心等待"
                    );
                    }
                }
                } else {
                    this.$message.error(res.message);
                }
            });
        })
        .catch(() => {});
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 64;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">

</style>

